import { BrowserRouter, Routes, Route } from 'react-router-dom';

import GettingStartedPage from './pages/GettingStarted';
import AboutPage from './pages/About';

import './App.css';
import './fonts/Lufga/LufgaRegular.ttf';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<GettingStartedPage />} />
          <Route path='/about' element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
