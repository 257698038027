
import { useEffect } from 'react';
import Footer from '../../components/Footer';
import logo from '../../logo.svg';

function AboutPage(): JSX.Element {

    useEffect(() => {
        document.title = 'Chirpp - About';
    }, []);

    return (
        <div>
            <img src={logo} className="App-logo" alt="logo" />
            <h1>About Chirpp</h1>
            <hr />
            <div style={{ textAlign: 'start' }}>
                <h2>Introduction</h2>
                <p>Chirpp, developed by Freenesis, is a groundbreaking decentralized application (DApp) designed for seamless communication. Unlike traditional applications that rely on centralized servers, Chirpp operates on a decentralized computing system, ensuring autonomy and reducing the risk of single-point failures.</p>

                <h2>Our Vision</h2>
                <p>At Freenesis, we believe in the power of simple and effective communication. With the rise of decentralized technologies, we saw an opportunity to create a platform that stands apart from conventional chat applications. Chirpp is our answer to the growing demand for secure, decentralized communication platforms.</p>

                <h2>How Chirpp Works</h2>
                <ol>
                    <li><strong>Open-Source Backend</strong>: Our dedicated team has developed and open-sourced the backend of Chirpp. This allows anyone to self-host their instance, ensuring data privacy and control.</li>
                    <li><strong>Registry Service</strong>: Once a backend is hosted, its details are registered with Chirpp's registry service. This facilitates seamless integration and communication with other self-hosted instances.</li>
                    <li><strong>Gossip Protocol</strong>: To ensure real-time updates and synchronization, Chirpp employs the gossip protocol. This ensures that information about a newly joined backend is quickly propagated to other backends in the network.</li>
                    <li><strong>Mobile WebApp PWA</strong>: Users can easily configure the mobile web application with their backend address, providing flexibility and ease of use.</li>
                    <li><strong>Community Server</strong>: For those who prefer not to self-host, Chirpp offers a community server with certain limitations, ensuring that everyone can experience the power of decentralized chatting.</li>
                    <li><strong>End-to-End Encryption</strong>: Your privacy is our utmost priority. All messages on Chirpp are encrypted end-to-end, ensuring that only the intended recipient can read them.</li>
                    <li><strong>gRPC Communication</strong>: The communication between different backends is facilitated using gRPC, a high-performance, open-source framework.</li>
                </ol>

                <h2>Why Choose Chirpp?</h2>
                <p>Decentralized applications, or DApps, have revolutionized the way we think about software and applications. Unlike traditional apps, DApps are not owned or controlled by a single entity. They operate autonomously, ensuring trustless and transparent operations. Chirpp, built on this principle, offers a unique chatting experience that prioritizes user privacy, security, and freedom.</p>

                <h2>Join the Revolution</h2>
                <p>With Chirpp, we're not just offering a chat platform; we're pioneering a movement towards decentralized communication. Be a part of this revolution and experience the future of chatting today.</p>

            </div>
            <Footer />
        </div>
    );
}

export default AboutPage;