import { Link } from 'react-router-dom';
import logo from '../../logo.svg';

export function GettingStartedPage(): JSX.Element {
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <img src={logo} className="App-logo" alt="logo" />
            <h1 style={{ fontSize: '48px', margin: '10px 0px 10px 0px' }}>Chirpp</h1>
            <p style={{ fontSize: '18px' }}>Text <span className='italic'><strong>Perpetually</strong></span></p>
            <p>Coming Soon</p>
            <Link to='/about' style={{ textDecoration: 'underline', color: '#404040' }}><strong>About</strong></Link>
        </div>
    )
}

export default GettingStartedPage;